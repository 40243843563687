
.endMatter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;


  @media (max-width: 1337px) {
    padding: 0;
    margin: 0 20px;
    padding-top: 20px;
  }

  @media (max-width: 1000px) {
    border-top: 1px solid rgba(0,0,0, 0.2);
    padding-top: 30px;
  }
}

.endMatter2 {
  @extend .endMatter;
  border-top: none;
  padding-bottom: 20px;
  margin-bottom: 0;
  font-weight: 500;
  
  @media (max-width: 450px) {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    .termsLinks {
      a {
        margin: 16px 0;
        display: block;
      }
    }
  }
}

.termsLinks a {
  margin-right: 32px;
}
