@use 'sass:math';

.container {

}

%pageConstrain {
  max-width: 1276px;
  margin: 0 auto; 

  @media (max-width: 1337px) {
    margin: 0;
    padding: 0 20px;
  }
}

%pageGrid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
}

.heroOuter {

  background-size: 150%;
  background-repeat: no-repeat;
  background-position: top center;

  overflow-x: hidden;

}

.hero {
  @extend %pageConstrain;
  padding-top: 70px;
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    overflow-x: hidden;
  }
}

.headerLogo {
  padding: 10px 0;
  width: 72px;

  @media (max-width: 1000px) {
    width: 60px;
  }
}

.header {
  @extend %pageConstrain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: background-color 0.3s ease;

  position: fixed;
  top: 40px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);

  &.scrolling {
    background-color: rgba(255, 255, 255, 0.9);
  }

  @media (max-width: 1000px) {
    left: 0;
    top: 20px;
    transform: none;
    margin: 0 20px;
    width: calc(100% - 40px);

    .gradientButton {
      width: auto;
      padding: 16px 24px;
    }
  }
}
.headerButtons {
  display: flex;
  align-items: center;
  gap: 20px;
}

.heroContent {
  height: 700px;
  position: relative;
  z-index: 10;
  flex: 1 0 auto;
  margin-top: 40px;

  @media (max-width: 1000px) {
    padding-top: 52vw;
    height: auto;
  }

  @media (max-width: 500px) {
    padding-top: 325px; 
  }
}


.heroImage {
  height: 100%;
  width: 100%;
  background: url('/homepage/hero.jpg') center right/90% no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  transform: translateX(30%);

  @media (max-width: 1000px) {
    transform: none;
    background-position: top center;
    background-size: 100%;
  }

  @media (max-width: 500px) { // iPhone 6/7/8+ & 11
    height: 50%;
    transform: scale(1.5) translateY(40px);
  }

  @media (max-width: 400px) {
    height: 50%;
    transform: scale(1.75) translateY(50px);
  }
}

.wishlistContent {
  height: auto;
  position: relative;
  z-index: 10;
  flex: 1 0 auto;
  margin-top: 40px;

  @media (max-width: 1000px) {
    padding-top: 5vw;
    height: auto;
  }

  @media (max-width: 500px) {
    padding-top: 15px; 
  }
}


.profilePic {
  border-radius: 50%;
  background-size: cover;
  display: inline-block;

  &.first {
    min-width: 100px;
    min-height: 100px;
    @media (max-width: 800px) {
      min-width: 80px;
      min-height: 80px;
    }
  }

  &.second {
    min-width: 60px;
    min-height: 60px;
  }

  &.third {
    min-width: 50px;
    min-height: 50px;
  }

  &.others {
    min-width: 40px;
    min-height: 40px;
  }
}

.grayButton {
  padding: 20px 0;
  width: 234px;
  background: rgba(21, 18, 25, 0.05);
  border-radius: 50000px;
  display: inline-block;
  text-align: center;

  opacity: 0.8;

  transition: background-color 150ms ease-in;

  &:hover {
    background: rgba(21, 18, 25, 0.15);
  }

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    padding: 16px 0;
  }
}

.gradientButton {
  padding: 20px 0;
  color: #fff;
  width: 234px;
  opacity: 0.8;
  
  border-radius: 50000px;
  position: relative;
  z-index: 10;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
    padding: 16px 0;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    content: '';
    background: linear-gradient(206.67deg, #BF55A7 12.41%, #A7BAF4 44.58%, rgba(167, 186, 244, 0) 74.43%);
    opacity: 0.6;
    filter: blur(45px);
    border-radius: 1071px;
    transform: translate(-50%, -50%) rotate(-180deg);
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-radius: inherit;
    background: linear-gradient(107.61deg, #663EBE 53.49%, #303DBB 94.49%);
    z-index: -1;
  }
}



.leaderboardViewWishlistFirst {

  color: #fff;
  opacity: 0.8;
  
  border-radius: 50000px;
  position: relative;
  z-index: 10;
font-size:18px;
 

  padding: 20px 25px;
  color: #fff;
  opacity: 0.8;
  
  border-radius: 50000px;
  position: relative;
  z-index: 10;
  

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    content: '';
    background: linear-gradient(206.67deg, #BF55A7 12.41%, #A7BAF4 44.58%, rgba(167, 186, 244, 0) 74.43%);
    opacity: 0.6;
    filter: blur(45px);
    border-radius: 1071px;
    transform: translate(-50%, -50%) rotate(-180deg);
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-radius: inherit;
    background: linear-gradient(107.61deg, #663EBE 53.49%, #303DBB 94.49%);
    z-index: -1;
  }


}

.leaderboardViewWishlistSecond {

  color: #fff;
  opacity: 0.8;
  
  border-radius: 50000px;
  position: relative;
  z-index: 10;

  font-size: 18px;
  padding: 10px 15px;
  color: #fff;

  opacity: 0.8;
  
  border-radius: 50000px;
  position: relative;
  z-index: 10;
  

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    content: '';
    background: linear-gradient(206.67deg, #BF55A7 12.41%, #A7BAF4 44.58%, rgba(167, 186, 244, 0) 74.43%);
    opacity: 0.6;
    filter: blur(45px);
    border-radius: 1071px;
    transform: translate(-50%, -50%) rotate(-180deg);
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-radius: inherit;
    background: linear-gradient(107.61deg, #663EBE 53.49%, #303DBB 94.49%);
    z-index: -1;
  }
}

.leaderboardViewWishlistThird {

  color: #fff;
  opacity: 0.8;
  
  border-radius: 50000px;
  position: relative;
  z-index: 10;

  font-size: 16px;
  padding: 8px 13px;

  color: #fff;
 
  opacity: 0.8;
  
  border-radius: 500px;
  position: relative;
  z-index: 10;
  

  &::before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    content: '';
    background: linear-gradient(206.67deg, #BF55A7 12.41%, #A7BAF4 44.58%, rgba(167, 186, 244, 0) 74.43%);
    opacity: 0.6;
    filter: blur(45px);
    border-radius: 1071px;
    transform: translate(-50%, -50%) rotate(-180deg);
    z-index: -1;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    border-radius: inherit;
    background: linear-gradient(107.61deg, #663EBE 53.49%, #303DBB 94.49%);
    z-index: -1;
  }

}

.leaderboardViewWishlist {

  color: darkblue;
  
  border-radius: 50em; /* Updated border-radius */
  position: relative;
  z-index: 10;
  opacity: 0.5;

  font-size: 14px;
  padding: 5px 10px;
  background: none;
  border: 2px solid transparent; /* Kept the border thickness */
  
  background: linear-gradient(#f7f7f8, #f7f7f8) padding-box, 
              linear-gradient(to right, darkblue, darkorchid) border-box; /* Updated gradient background */
  -webkit-background-clip: padding-box, border-box; /* For Safari */
  background-clip: padding-box, border-box;
}

.featuresTitle {
  font-size: 35px;
  font-weight: 200;
  letter-spacing: -1px;
  color: #2C2634;
  font-family: 'soehne-web', sans-serif;

  b {
    font-weight: 500;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }
}

.featuresBody {
  font-size: 18px;
  color: rgba(21, 18, 25, 0.51);
  line-height: 130%;
  font-family: 'soehne-web', sans-serif;
  font-weight: 300;

  @media (max-width: 1000px) {
    font-size: 14px;
  }
}

$imageRatioPadding: math.div(267, 360.9)*100%;

.optionality, .delight, .security {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 30px;
  z-index: 150;
  padding: 70px;

  @media (max-width: 1000px) {
    padding: 45px;
  }

  @media (max-width: 500px) {
    padding: 32px;
  }
}
.optionality {
  grid-area: optionality;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: url(/homepage/optionality.png) 90% center/contain no-repeat,
    linear-gradient(96.86deg, #F9E5BE 18.05%, #F1CEE7 46.55%, #F6D7C0 92.98%);
  
  .featuresTitle {
    max-width: 486px;
  }
  
  .featuresBody {
    max-width: 310px;
  }

  @media (max-width: 1000px) {

    .featuresTitle {
      max-width: none;
    }

    justify-content: flex-end;
    background-size: 100%;
    background-position-y: top;
    padding-top: calc(#{$imageRatioPadding} + 30px);
  }
}

.delight, .security {
  display: flex;
  flex-direction: column;
  padding-top: 66%;
}

.delight {
  grid-area: delight;

  background: url(/homepage/delight.png) center top/100% no-repeat,
    linear-gradient(138.69deg, #F4F9B9 1.78%, #FEF0FD 60.58%, #ECEFF7 98.52%);

  .featuresBody {
    max-width: 473px;
  }

  @media (max-width: 1000px) {
    padding-top: calc(#{$imageRatioPadding} + 30px);
  }

  @media (max-width: 500px) {
    background-size: 100%;
  }
}
.security {
  grid-area: security;
  background: url(/homepage/security.png) center 40px/66% no-repeat,
    linear-gradient(141.8deg, #EBF7F1 15.7%, #E2F2FD 55.9%, #F6FBC6 109.22%) no-repeat;

  .featuresBody {
    max-width: 325px;
  }

  @media (max-width: 1000px) {
    padding-top: calc(#{$imageRatioPadding} + 30px);
  }

  @media (max-width: 500px) {

  }
}

.wishlist{
  @extend %pageConstrain;
  display: flex;
  justify-content: center;
  margin-top: 15px;

    ul {
      width: 70%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      @media (max-width: 800px) {
        width: 100%;
      }
   
      li {
  
        width: 100%; 
        border-radius: 10px;
        margin: 5px auto;
        padding: 5px 10px;

     
        .ranking{
          font-size: 20px;
          width: 50px;
        }

        /* Hide on screens smaller than 768px */
@media (max-width: 767px) {
  .hide-on-small {
    display: none;
  }
}


        &:nth-child(1) {
          background: linear-gradient(96.86deg, #F9E5BE 18.05%, #F1CEE7 46.55%, #F6D7C0 92.98%);
          padding: 30px 10px;
        }
        &:nth-child(2) {
          background: linear-gradient(138.69deg, #F4F9B9 1.78%, #FEF0FD 60.58%, #ECEFF7 98.52%);
          padding: 25px 10px;
        }
        &:nth-child(3) {
          background:  linear-gradient(141.8deg, #EBF7F1 15.7%, #E2F2FD 55.9%, #F6FBC6 109.22%) no-repeat;
          padding: 20px 10px;
        }
        &:nth-child(n+4) {
          background: #3d35490a;
        }
      }
        
     
      }
    
  
  }

.features {
  @extend %pageConstrain;
  z-index: 100;
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 520px 628px;
  grid-template-areas:
   "optionality optionality"
   "delight security";

   margin-top: 48px;

   @media (max-width: 1000px) {
    grid-template-areas:
    "optionality"
    "delight"
    "security";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto;
    margin-top: 0;
  }
}

.anyBrand {
  border: 1px solid currentColor;
  border-radius: 5000px;
  padding: 0 32px;
  padding-left: 64px;
  display: inline-block;
  margin-left: 8px;
  position: relative;
  line-height: 120%;

  &::after {
    content: '';
    width: 40px;
    height: 40px;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-40%);
    background: url(/homepage/logo_star.png) center center/contain no-repeat;
  }

  @media (max-width: 700px) {
    padding: 0 16px;
    padding-left: 32px;

    &::after {
      left: 10px;
      width: 24px;
      height: 24px;
    }
  }

}

.pageTitle {
  font-size: 60px;
  color: #3D3549;
  font-family: 'soehne-web', sans-serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 140%;
  /* or 71px */
  
  letter-spacing: -0.045em;

  @media (max-width: 1000px) {
    text-align: center;
    font-size: 48px;
  }

  @media (max-width: 700px) {
    font-size: 30px;
  }
}

.footerTitle {
  @extend .pageTitle;
  font-weight: 200;
  line-height: 102%;
  margin-bottom: 40px;
  text-align: center;

  display: inline-block;
  position: relative;

  &::after {
    content: '';
    width: 72%;
    height: 20px;
    position: absolute;
    bottom:  0;
    right: -28px;
    background: url('/homepage/underline.svg') bottom right no-repeat;
    z-index: -1;
  }

  
  @media (max-width: 1000px) {
    margin-bottom: 24px;

    &::after {
      bottom: -5px;
    }
  }
}


.featuredLists {
  @extend %pageConstrain;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-bottom: 60px;

  @media (max-width: 1000px) {
    width: 285%;
    padding-right: 10%;
  }
}

.featuredListsTrack {
  @media (max-width: 1000px) {
    width: 100%;
    overflow: hidden;
  }
}

.featuredListsTitle {
  font-family: 'soehne-web', sans-serif;
  font-size: 48px;
  text-align: center;
  letter-spacing: -0.045em;
  margin: 95px 0 56px;

  @media (max-width: 1000px) {
    text-align: left;
    margin: 40px 30px 20px;
    font-size: 30px;
  }
}

.featuredListTitle {
  font-family: 'soehne-web', sans-serif;
  font-size: 36px;
  letter-spacing: -0.045em;
  margin: 15px 0 10px;

  @media (max-width: 1000px) {
    font-size: 30px;
    margin: 15px 0 5px;
  }
}

.featuredListImage {
  border-radius: 30px;
}

.footer {
  @extend %pageConstrain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: url('/homepage/footerBubbles.svg') center center/100% no-repeat;

  padding: 120px 0;

  
  @media (max-width: 1000px) {

    background: url('/homepage/footerBubblesSmall.svg') center center/100% no-repeat;
    flex-direction: column;
    justify-content: flex-start;

    .gradientButton {
      width: 234px;
    }
  }

  
}

.footerContent {
  @media (max-width: 1000px) {
    width: 100%;
    padding-top: 60px;
  }
}

.footerFree {
  margin-top: 16px;
  font-size: 14px;
  color: rgba(13, 9, 19, 0.45);
}


.favImage {
  border-radius: 28px;
}

.endMatter {
  @extend %pageConstrain;
}

